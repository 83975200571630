import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default function AppointmentPage() {
  return (
    <Layout>
      <SEO title={"Book an appointment with LiaLash Beauty in Roseberry"} />
      <h1>Book an Appointment</h1>
      <div className="container">
        <iframe
          src="https://bookings.gettimely.com/lialashbeauty/bb/book"
          title="Schedule Appointment"
          width="100%"
          height="800"
          frameBorder="0"
        ></iframe>
      </div>
    </Layout>
  );
}
